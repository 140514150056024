import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {post} from '../service/httpService';

const LoginPage = () => {
    const [formData, setFormData] = useState({
        email: '',
        password: '',
    });
    const [notification, setNotification] = useState(null);
    const navigate = useNavigate();

    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await post('/sso/account/login', formData);
            if (response.data.token) {
                const token = response.data.token;
                localStorage.setItem('authToken', token); // Store token in localStorage
                localStorage.setItem('name', response.data.name); // Store user name in localStorage
                     // Send token to the C# server running on localhost:8080
            // Send token to the C# server running on localhost:8080
                console.log(token);
                try {
                    fetch('http://localhost:8080/', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({ token }),
                    }).catch((err) => {
                        console.log("Failed to send token to localhost:8080. Server may not be running.");
                    });
                } catch (fetchError) {
                    console.log("Error sending token to localhost:8080:", fetchError.message);
                }

                const lastVisitedPath = localStorage.getItem('lastVisitedPath');



                if (lastVisitedPath) {
                    localStorage.removeItem('lastVisitedPath');
                    navigate(lastVisitedPath);
                } else {
                    navigate('/profile');
                }
                setNotification({
                    type: 'success',
                    message: 'Login successful!',
                });

            } else {
                setNotification({
                    type: 'error',
                    message: 'Login failed. Please check your credentials or verify your email.',
                });
            }
        } catch (error) {
            const errorMessage = error.response?.data?.detail || error.message || 'An unexpected error occurred';
            setNotification({
                type: 'error',
                message: errorMessage,
            });
        }
    };

    return (
        <div className="flex items-center justify-center min-h-screen bg-gray-100">
            <div className="w-full max-w-md p-8 bg-white shadow-lg -mt-52 rounded-lg">
                <h1 className="text-2xl font-bold text-center text-gray-900 mb-6">Login</h1>
                {notification && (
                    <div
                        className={`p-4 mb-4 text-white ${notification.type === 'success' ? 'bg-green-500' : 'bg-red-500'} rounded-md`}>
                        {notification.message}
                    </div>
                )}
                <form onSubmit={handleSubmit}>
                    <div className="mb-4">
                        <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email</label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            required
                        />
                    </div>
                    <div className="mb-6">
                        <label htmlFor="password" className="block text-sm font-medium text-gray-700">Password</label>
                        <input
                            type="password"
                            id="password"
                            name="password"
                            value={formData.password}
                            onChange={handleChange}
                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            required
                        />
                    </div>
                    <button
                        type="submit"
                        className="w-full py-2 px-4 bg-indigo-600 text-white font-semibold rounded-lg shadow-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                    >
                        Login
                    </button>
                    <div className="mt-4 text-center">
                        <button
                            type="button"
                            onClick={() => navigate('/register')} // Redirect to the signup page
                            className="text-indigo-600 hover:text-indigo-800 mt-4 block mx-auto"
                        >
                            Don't have an account? Sign Up
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default LoginPage;
